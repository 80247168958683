export const enum LungListTabEnum {
    Profession = "profession",
    Home = "home",
}
/**
 * @description 哮喘状态
 */
export const enum AsthmaEnum{
    Normal = 1,
    Warn = 2,
    Dander = 3,
}

/**
 * @description 慢阻肺状态
 * Normal 正常:1
 * Mild 轻度:2
 * Moderate 中度：3
 * Serious 重度：4
 * SeriousPlus 极重度：5
 */
export const enum HinderAsthmaEnum {
    Normal = 1,
    Mild = 2,
    Moderate = 3,
    Serious = 4,
    SeriousPlus = 5,
}