
import { Component, Watch, Mixins, Prop } from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import Resize from "@/mixins/resize";

import { getPefHomeHealthAllList, updatePefHomeHealth } from "@/api/lung";

import { handleBackendDataWithDefaults } from "@/utils/common";

import { LungListTabEnum, AsthmaEnum, HinderAsthmaEnum } from "@/enum/lung.enum";

@Component
export default class Home extends Mixins(loading, tablePagination, Resize) {
    @Prop({ default: LungListTabEnum.Profession }) readonly tabActivated: LungListTabEnum|undefined;
    tableData = [];
    itemTotal = 0;

    dialogVisible = false;
    btnLoading = false;
    formData = {
        id: "",
        resultSuggestion: "",
        resultSuggestionM: "",
        resultSuggestionW: "",
        minorityResultSuggestion: ""
    }
    editorOption = {
        modules: {
        toolbar: [["bold", { color: [] }], [{ size: [] }]],
        },
    };

    asthmaStatus = {
        [AsthmaEnum.Normal] : "正常",
        [AsthmaEnum.Warn] : "警告",
        [AsthmaEnum.Dander] : "危险"
    }

    hinderAsthmaStatus = {
        [HinderAsthmaEnum.Normal]: "正常",
        [HinderAsthmaEnum.Mild]: "轻度",
        [HinderAsthmaEnum.Moderate]: "中度",
        [HinderAsthmaEnum.Serious]: "重度",
        [HinderAsthmaEnum.SeriousPlus]: "极重度"
    }

    activeName = "first";

    mounted() {
        this.windowResize(270)
    }

    getData() {
        this.showLoading();
        getPefHomeHealthAllList({
            pageNum: this.currentPage,
            pageSize: this.pageSize,
        }).then(res => {
            this.hideLoading();
            this.tableData = res.data;
        }).finally(() => {
            this.hideLoading();
        });
    }

    edit(row: { id: string, resultSuggestion: string, resultSuggestionM: string, resultSuggestionW: string, minorityResultSuggestion: string }) {
        this.formData.id = row.id;
        this.formData.resultSuggestion = row.resultSuggestion;
        this.formData.resultSuggestionM = row.resultSuggestionM;
        this.formData.resultSuggestionW = row.resultSuggestionW;
        this.formData.minorityResultSuggestion = row.minorityResultSuggestion;
        this.dialogVisible = true;
    }

    dialogClose() {
        this.dialogVisible = false;
        this.btnLoading = false;
        // @ts-ignore
        this.$refs.validateForm?.resetFields();
        this.formData.id = "";
        this.activeName = "first";
    }

    dialogConfirm() {
        this.btnLoading = true;
        updatePefHomeHealth(this.formData).then((res) => {
            this.$message.success("操作成功")
            this.dialogClose();
            this.getData();
        }).catch(() => {
            this.btnLoading = false;
        })
    }

    @Watch("tabActivated", { immediate: true, deep: true })
    setLoading(value: LungListTabEnum) {
        console.log("tabActivated", value)
        if (value === LungListTabEnum.Home) {
            this.getData();
        }
    }
}

