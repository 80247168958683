
import { Component, Watch, Mixins, Prop } from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import Resize from "@/mixins/resize";

import { LungListTabEnum, AsthmaEnum, HinderAsthmaEnum } from "@/enum/lung.enum";

import { getPefSuggestAllList, updatePefSuggest } from "@/api/lung";

import { deepClone } from "lodash";

interface LungRecord{
    id: number,
    resultKey1: number,
    resultKey2: number,
    resultSuggestion: string,
    updateTime: string
}

@Component
export default class Profession extends Mixins(loading, tablePagination, Resize) {
    @Prop({ default: LungListTabEnum.Profession }) readonly tabActivated: LungListTabEnum|undefined;

    tableData: Array<LungRecord> = [];
    itemTotal = 0;

    dialogVisible = false;
    btnLoading = false;
    formData = {
        id: "",
        resultSuggestion: "",
    }
    editorOption = {
        modules: {
        toolbar: [["bold", { color: [] }], [{ size: [] }]],
        },
    };
    asthmaStatus = {
        [AsthmaEnum.Normal] : "正常",
        [AsthmaEnum.Warn] : "警告",
        [AsthmaEnum.Dander] : "危险"
    }

    hinderAsthmaStatus = {
        [HinderAsthmaEnum.Normal]: "正常",
        [HinderAsthmaEnum.Mild]: "轻度",
        [HinderAsthmaEnum.Moderate]: "中度",
        [HinderAsthmaEnum.Serious]: "重度",
        [HinderAsthmaEnum.SeriousPlus]: "极重度"
    }

    mounted() {
        this.windowResize(330)
    }

    getData() {
        this.showLoading();
        getPefSuggestAllList({ pageNum: this.currentPage, pageSize: this.pageSize, }).then((res) => {
            console.log("=====", res.data)
            this.tableData = res.data;
            // this.itemTotal = res.data.total;
        }).finally(() => {
            this.hideLoading();
        })
    }

    edit(row: { id: string, resultSuggestion: string }) {
        this.formData.id = row.id;
        this.formData.resultSuggestion = row.resultSuggestion;
        this.dialogVisible = true;
    }

    dialogClose() {
        this.dialogVisible = false;
        this.btnLoading = false;
        // @ts-ignore
        this.$refs.validateForm?.resetFields();
        this.formData.id = "";
    }

    dialogConfirm() {
        this.btnLoading = true;
        updatePefSuggest(this.formData).then((res) => {
            this.$message.success("操作成功")
            this.dialogClose();
            this.getData();
        }).catch(() => {
            this.btnLoading = false;
        })
    }

    @Watch("tabActivated", { immediate: true, deep: true })
    setLoading(value: LungListTabEnum) {
        console.log("currentTab", value)
        if (value === LungListTabEnum.Profession) {
            this.getData();
        }
    }
}

